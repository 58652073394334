.la-color-1{
    color: $base-color-font;
}
.la-color-2{
    color: $secondory-color-font;
}

.la-container{

    padding: 0 10px;

}


.la-flex{
    display: flex;
}


.text-center{
    text-align: center;
}


.modal-wrapper.sc-ion-modal-md, .modal-shadow.sc-ion-modal-md{
    box-shadow:none;
    overflow: visible;

    .ion-page{
        overflow: unset;
    }
}
